import React from 'react';
import { Stack, Text, Link, FontWeights, IStackTokens } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

const GettingStarted: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Stack
            verticalAlign="center"
            horizontalAlign="center"
            styles={stackStyles}
            tokens={stackTokens}
        >
            <Stack
                styles={contentStyles}
                tokens={contentTokens}
            >
                    <Text variant="xxLarge" styles={headingStyles}>
                        Getting Started with Our Teams App
                    </Text>
                    <Text variant="medium" styles={textStyles}>
                        Welcome to our Teams app! This application is designed to work seamlessly across Teams platforms, including mobile, desktop, and web. Here's how you can get started:
                    </Text>
                    <Text variant="large" styles={sectionHeadingStyles}>
                        Installation
                    </Text>
                    <ol style={listStyles}>
                        <li>Open Microsoft Teams</li>
                        <li>Click on the Apps icon in the left sidebar</li>
                        <li>Search for our app name in the search bar</li>
                        <li>Click on the app and then click "Add" to install it to your Teams environment</li>
                    </ol>
                    <Text variant="large" styles={sectionHeadingStyles}>
                        Using the App
                    </Text>
                    <ol style={listStyles}>
                        <li>Once installed, you can access the app from your Teams sidebar</li>
                        <li>Click on the app icon to open it</li>
                    </ol>
                    <Text variant="large" styles={sectionHeadingStyles}>
                        Supported Platforms
                    </Text>
                    <ul style={listStyles}>
                        <li>Teams Desktop application</li>
                        <li>Teams Mobile app (iOS and Android)</li>
                        <li>Teams Web interface</li>
                    </ul>
                    <Text variant="medium" styles={textStyles}>
                        If you encounter any issues or need further assistance, please visit our{" "}
                        <Link onClick={() => navigate("/contact")} styles={inlineLink}>Contact Us</Link> page.
                    </Text>
                    <Stack horizontal tokens={{ childrenGap: 20 }} styles={linkContainerStyles}>
                        <Link onClick={() => navigate('/')} styles={linkStyles}>Home</Link>
                        <Link onClick={() => navigate('/privacy')} styles={linkStyles}>Privacy Policy</Link>
                        <Link onClick={() => navigate('/termsofuse')} styles={linkStyles}>Terms of Use</Link>
                    </Stack>
            </Stack>
        </Stack>
    );
};

// Styles
const stackStyles = {
    root: {
        height: '100vh',
        backgroundColor: '#f4f4f4',
        padding: '20px',
    },
};

const stackTokens: IStackTokens = { childrenGap: 15 };

const contentStyles = {
    root: {
        width: '800px',
        padding: '40px',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 40px)', // Subtract padding
    },
};

const contentTokens: IStackTokens = { childrenGap: 20 };

const headingStyles = {
    root: {
        fontWeight: FontWeights.semibold,
        color: '#333',
        textAlign: 'center',
        marginBottom: '20px',
    },
};

const sectionHeadingStyles = {
    root: {
        fontWeight: FontWeights.semibold,
        color: '#333',
        marginTop: '20px',
        marginBottom: '10px',
    },
};

const textStyles = {
    root: {
        color: '#666',
        lineHeight: '1.6',
        textAlign: 'left',
    },
};

const listStyles = {
    marginTop: '10px',
    marginBottom: '10px',
    paddingLeft: '20px',
    color: '#666',
    lineHeight: '1.6',
};

const linkContainerStyles = {
    root: {
        justifyContent: 'center',
        marginTop: '30px',
    },
};

const linkStyles = {
    root: {
        fontSize: '16px',
        color: '#0078d4',
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline',
        },
    },
};

const inlineLink = {
    root: {
        color: '#0078d4',
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline',
        },
    },
};

export default GettingStarted;

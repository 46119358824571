import * as React from 'react';
import { useState, useEffect } from "react";
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { useBoolean } from '@fluentui/react-hooks';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { TextField } from '@fluentui/react/lib/TextField';
import { Button } from '@fluentui/react-components';
import { LauncherSettingsRegular } from "@fluentui/react-icons";
import { Stack } from '@fluentui/react/lib/Stack';
import { CardValues } from './common/CardValues';

interface iCardConfiguratorProps {
	cardValues:string[]
 	onCardConfigurationChanged: (newCardValues: string []) => void,
}

const CardConfigurator = ({cardValues, onCardConfigurationChanged}: iCardConfiguratorProps) =>{
  	const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  	const dialogContentProps = {
		type: DialogType.normal,
		title: 'Configure Card Values',
	};

	const estimationValueOptions:any = {
		"HR": CardValues.Hours,
		"SP": CardValues.StoryPoints,
		"TS": CardValues.TSHIRT,
		"CS": CardValues.CUSTOM
	};

  	const [selectedEstimationValues, setEstimationvalue] = useState(cardValues);
	const [selectedEstimationType, setSelectedEstimationType] = useState<string>("");

	const determineEstimationType = (values: string[]): string => {
		if (JSON.stringify(values) === JSON.stringify(CardValues.Hours)) return "HR";
		if (JSON.stringify(values) === JSON.stringify(CardValues.StoryPoints)) return "SP";
		if (JSON.stringify(values) === JSON.stringify(CardValues.TSHIRT)) return "TS";
		return "CS";
	};

	useEffect(() => {
		setSelectedEstimationType(determineEstimationType(cardValues));
	}, [cardValues]);

	const estimationTypeSelected = function(event:React.FormEvent<HTMLDivElement>, option?:IDropdownOption, index?:number){    
		if (option !== undefined){
			setSelectedEstimationType(option.key as string);
			setEstimationvalue(estimationValueOptions[option.key]);
		}
	}

	const changeEstTextField = function(event:any, newValue:any){
		setEstimationvalue(newValue.split(';'));
	}

	const saveCardConfiguration = function(){
		onCardConfigurationChanged(selectedEstimationValues);
		toggleHideDialog();
	}


	return (
		<div>
			<Button appearance="outline" onClick={toggleHideDialog} icon={<LauncherSettingsRegular />}/>
			<Dialog
				hidden={hideDialog}
				onDismiss={toggleHideDialog}
				dialogContentProps={dialogContentProps}
				minWidth="320px"
				maxWidth="450px"
			>
				<Stack tokens={{ childrenGap: 20 }}>
					<Dropdown
						placeholder="Please select estimation type"
						selectedKey={selectedEstimationType}
						onChange={estimationTypeSelected}
						options={[
							{ key: "HR", text: "Hours"},
							{ key: "SP", text: "Story Points"},
							{ key: "TS", text: "T-Shirt Sizes" },
							{ key: "CS", text: "Custom" }
						]}
						required={true}
						styles={{ dropdown: { width: '100%' } }}
					/>
					<TextField 
						value={selectedEstimationValues?.join(";")} 
						onChange={changeEstTextField}
						styles={{ fieldGroup: { width: '100%' } }}
					/>
				</Stack>
				<DialogFooter>
					<PrimaryButton onClick={saveCardConfiguration} text="Save" />
					<DefaultButton onClick={toggleHideDialog} text="Cancel" />
				</DialogFooter>
			</Dialog>
		</div>
	);
};

export default CardConfigurator;

import React from "react";
import { Stack, Text, Label, FontWeights, Link, IStackTokens } from '@fluentui/react';
import { useNavigate } from "react-router-dom";

/**
 * This component is used to display contact information
 * and support details for customers who may have
 * questions or issues with the product.
 */
const ContactUs: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      styles={stackStyles}
      tokens={stackTokens}
    >
      <Stack
        styles={contentStyles}
        tokens={contentTokens}
      >
        <Text variant="xxLarge" styles={headingStyles}>
          Contact Us
        </Text>

        <Label styles={subheadingStyles}>Get in Touch with the Alfred Team</Label>
        <Text variant="medium" styles={textStyles}>
          We're here to help you make the most of Agile Alfred. Whether you have questions, feedback, or need assistance, don't hesitate to reach out to us.
        </Text>

        <Label styles={subheadingStyles}>Email</Label>
        <Text variant="medium" styles={textStyles}>
          For all inquiries, please email us at:
          <Link href="mailto:alfred@techbase.ro" styles={linkStyles}>alfred@techbase.ro</Link>
        </Text>

        <Label styles={subheadingStyles}>Response Time</Label>
        <Text variant="medium" styles={textStyles}>
          We strive to respond to all emails within 24 hours during business days.
        </Text>

        <Label styles={subheadingStyles}>Customer Support</Label>
        <Text variant="medium" styles={textStyles}>
          Our dedicated customer support team is available to assist you with any issues or questions you may have about Agile Alfred.
        </Text>

        <Label styles={subheadingStyles}>Support Hours</Label>
        <Text variant="medium" styles={textStyles}>
          Monday through Friday: 9:00 AM to 5:00 PM Eastern European Time (EET)
        </Text>
        
        <Label styles={subheadingStyles}>Feedback</Label>
        <Text variant="medium" styles={textStyles}>
          We value your input! If you have suggestions for improving Agile Alfred or ideas for new features, please share them with us. Your feedback helps us make Agile Alfred better for everyone.
        </Text>

        <Text variant="medium" styles={textStyles}>
          Thank you for choosing Agile Alfred. We're committed to providing you with excellent support and continuously improving your experience with our product.
        </Text>
        <Stack horizontal tokens={{ childrenGap: 20 }} styles={linkContainerStyles}>
            <Link onClick={() => navigate('/')} styles={linkStyles}>Home</Link>
            <Link onClick={() => navigate('/privacy')} styles={linkStyles}>Privacy Policy</Link>
            <Link onClick={() => navigate('/termsofuse')} styles={linkStyles}>Terms of Use</Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

// Fluent UI Styles

const linkContainerStyles = {
  root: {
      justifyContent: 'center',
      marginTop: '30px',
  },
};

const stackStyles = {
  root: {
    minHeight: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '20px',
  },
};

const stackTokens: IStackTokens = { childrenGap: 15 };

const contentStyles = {
  root: {
    maxWidth: '800px',
    width: '100%',
    padding: '40px',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
  },
};

const contentTokens: IStackTokens = { childrenGap: 20 };

const headingStyles = {
  root: {
    fontWeight: FontWeights.semibold,
    color: '#333',
    textAlign: 'center',
    marginBottom: '20px',
  },
};

const subheadingStyles = {
  root: {
    fontWeight: FontWeights.semibold,
    color: '#555',
    marginTop: '15px',
    marginBottom: '5px',
  },
};

const textStyles = {
  root: {
    color: '#666',
    lineHeight: '1.6',
  },
};

const linkStyles = {
  root: {
    display: 'block',
    marginTop: '5px',
    color: '#0078d4',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  },
};

export default ContactUs;

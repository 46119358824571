import React from 'react';
import { ITeamsUser } from './utils/usePresence';
import { AppStateEnum } from './utils/useAppState';
import { Avatar, Text, Badge, PresenceBadge } from "@fluentui/react-components";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

interface IUserPersonaProps {
    user: ITeamsUser,
    appState: string,
}

export enum VoteStatus {
  noVote = "No vote",
  pendingVote = "Pending vote",
  voted = "Voted"
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.gap('12px'),
    ...shorthands.padding('8px'),
    width: '100%',
    boxSizing: 'border-box',
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground1Hover,
    },
  },
  avatar: {
    position: 'relative',
  },
  presenceBadge: {
    position: 'absolute',
    bottom: '-2px',
    right: '-2px',
    boxShadow: `0 0 0 2px ${tokens.colorNeutralBackground1}`,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 0,
  },
  name: {
    fontWeight: tokens.fontWeightSemibold,
    ...shorthands.overflow('hidden'),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  status: {
    color: tokens.colorNeutralForeground3,
    fontSize: tokens.fontSizeBase200,
    ...shorthands.overflow('hidden'),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  badge: {
    marginLeft: 'auto',
  },
});

const UserPersona: React.FC<IUserPersonaProps> = ({ user, appState }) => {
    const styles = useStyles();
    const displayName = user.isLocalUser ? `${user.displayName} (You)` : user.displayName;
    const status = user.hasVoted ? VoteStatus.voted : VoteStatus.pendingVote;
    
    const getBadgeContent = () => {
      if (appState !== AppStateEnum.results) {
        return (
          <PresenceBadge
            className={styles.presenceBadge}
            status={user.hasVoted ? "available" : "away"}
          />
        );
      }
      return (
        <Badge 
          className={styles.badge}
          appearance="outline" 
          color="brand"
        >
          {!user?.vote ? VoteStatus.noVote : user.vote}
        </Badge>
      );
    };

    return (
      <div className={styles.container}>
        <div className={styles.avatar}>
          <Avatar 
            name={displayName} 
            size={32}
          />
          {appState !== AppStateEnum.results && getBadgeContent()}
        </div>
        <div className={styles.textContainer}>
          <Text className={styles.name}>{displayName}</Text>
          <Text className={styles.status}>{status}</Text>
        </div>
        {appState === AppStateEnum.results && getBadgeContent()}
      </div>
    );
};

export default UserPersona;

import { useMemo, useState } from "react";
import {useSharedObjects} from "./utils/useSharedObjects";
import {IUserData, usePresence} from "./utils/usePresence";
import {useTeamsContext} from "./utils/useTeamsContext";
import { Spinner, SpinnerSize, Stack, useTheme, Text, FontWeights } from '@fluentui/react';
import VotingScreen from "./VotingScreen";
import ResultScreen from "./ResultsScreen";
import { useAppState, AppStateEnum } from "./utils/useAppState";
import HomeScreen from "./HomeScreen";

interface MainAppProps {
    webDebugMode: boolean;
}

export const MainApp: React.FC<MainAppProps> = ({ webDebugMode}) => {
    const theme = useTheme();
    const { context, isInTeams } = useTeamsContext(webDebugMode);

    const {
        container,
        presence,
        appState
    } = useSharedObjects(webDebugMode);

    const {
        appStarted,
        state,
        deckConfig,
        changeState,
        changeCards,
    } = useAppState(appState, 
            (newState: string| undefined)=>{ 
                if(newState === AppStateEnum.voting){
                    castVote("No Vote", false);
                }            
            },
            (newDeckConfig: string[]| undefined)=>{ 
                          
            }
        );


    const { 
        presenceStarted, 
        localUser, 
        users,
        castVote
    } = usePresence(presence, context);
       
    const loadText = useMemo(() => {        
        if (!context) {
            return "Loading Teams Client SDK...";
        }
        if (!container) {
            return "Joining Live Share session...";
        }
        if (!appStarted) {
            return "Starting live state...";
        };
        if (!presenceStarted) {
            return "Starting sync...";
        };
        return `App Loaded: ${presenceStarted}`;
    }, [context, container, presenceStarted, isInTeams, webDebugMode]);    

    const showResults = function(){
        changeState(AppStateEnum.results);
    };

    const resetVoting = function(){
        changeState(AppStateEnum.voting);
    };

    const castUserVote = function(vote: string, hasVoted: boolean){
        castVote(vote, hasVoted);
    }

    const updateCardConfiguration = function(newDeck: string[]){
        changeCards(newDeck);
        changeState(AppStateEnum.voting);
    }

    if(!presenceStarted){
        return (
            <Stack
                verticalAlign="center"
                horizontalAlign="center"
                styles={{
                    root: {
                        height: '100vh',
                        width: '100vw',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }
                }}
            >
                <Spinner
                    size={SpinnerSize.large}                    
                    labelPosition="bottom"
                    styles={{
                        root: { textAlign: 'center' },
                        label: { 
                            color: theme.palette.neutralPrimary,
                            fontSize: theme.fonts.mediumPlus.fontSize,
                            marginTop: '16px'
                        }
                    }}
                />
                <div style={{ 
                    marginTop: '8px', 
                    color: theme.palette.neutralSecondary,
                    fontSize: theme.fonts.medium.fontSize
                }}>
                    {loadText}
                </div>
                {webDebugMode && (
                    <div style={{
                        marginTop: '16px',
                        color: theme.palette.themePrimary,
                        fontSize: theme.fonts.medium.fontSize,
                        fontWeight: 'bold'
                    }}>
                        Debug Mode: ON
                    </div>
                )}
            </Stack>
        );
    }
    else{
        if(state === AppStateEnum.voting){
            return (
                <VotingScreen 
                    appState={state}
                    cardConfig={deckConfig}
                    onCastVote={(vote, hasVoted)=>castUserVote(vote, hasVoted)} 
                    onShowResults={showResults} 
                    onlineUsers={users}
                    changeCardDeck={(cardConfig)=> updateCardConfiguration(cardConfig)} 
                />
            );
        }else{
            return (
                <ResultScreen 
                    appState={state} 
                    onResetVoting={resetVoting} 
                    onlineUsers={users}
                />
            );
        }
    }
};

// Fluent UI Styles
const stackStyles = {
    root: {
        minHeight: '100vh',
        backgroundColor: '#f4f4f4',
        padding: '20px',
    },
};

const stackTokens = { childrenGap: 15 };

const contentStyles = {
    root: {
        width: '800px',
        padding: '40px',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    },
};

const contentTokens = { childrenGap: 20 };

const headingStyles = {
    root: {
        fontWeight: FontWeights.semibold,
        color: '#333',
        textAlign: 'center',
        marginBottom: '20px',
    },
};

const textStyles = {
    root: {
        color: '#666',
        lineHeight: '1.6',
        textAlign: 'center',
    },
};


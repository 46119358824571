// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  tokens,
} from "@fluentui/react-components";
import { HashRouter, Routes, Route } from 'react-router-dom';
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import ConfigTab from "./ConfigTab";
import { MainApp } from "./MainApp";
import Support from "./Support";
import HomeScreen from "./HomeScreen";
import { useInitializeTeamsApp } from "./utils/initializeTeamsApp";
import { isWebDebugMode } from "./utils/isWebDebugMode";
import ContactUs from "./ContactUs";
import GettingStarted from "./GettingStarted";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
    const webDebugMode = isWebDebugMode();
    const { teamsTheme, teamsSDKLoaded, initialized } = useInitializeTeamsApp(webDebugMode);

    if (!initialized) {
        return <div>Loading...</div>;
    }

    return (
        <FluentProvider
            theme={teamsTheme}
            style={{
                height: "100vh",
                width: "100vw",
                margin: 0,
                padding: 0,
                overflow: "hidden",
                backgroundColor: tokens.colorNeutralBackground1,
            }}
        >
            <HashRouter>                 
                <Routes>
                    <Route path="/" element={<HomeScreen />} />
                    <Route path="/mainapp" element={<MainApp webDebugMode={webDebugMode} />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/termsofuse" element={<TermsOfUse />} />
                    <Route path="/config" element={<ConfigTab />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/gettingstarted" element={<GettingStarted />} />
                    <Route path="/localdebug" element={<MainApp webDebugMode={true} />} />
                </Routes>
            </HashRouter>
        </FluentProvider>
    );
}

import React from 'react';
import { Stack, Text, Link, FontWeights, ScrollablePane, IStackTokens } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';

const HomeScreen: React.FC = () => {
    const navigate = useNavigate();

    return (
        <ScrollablePane styles={scrollablePaneStyles}>
            <Stack
                verticalAlign="center"
                horizontalAlign="center"
                styles={stackStyles}
                tokens={stackTokens}
            >
                <Stack
                    styles={contentStyles}
                    tokens={contentTokens}
                >
                    <Text variant="xxLarge" styles={headingStyles}>
                        Welcome to Agile Alfred
                    </Text>
                    <Text variant="xLarge" styles={subHeadingStyles}>
                        The Last Estimation Tool You Will Ever Need!
                    </Text>
                    <Text variant="medium" styles={textStyles}>
                        Agile Alfred is your intelligent assistant for agile planning and estimation. 
                        It helps teams collaborate effectively in Microsoft Teams, making sprint planning 
                        and story point estimation more efficient and engaging.
                    </Text>
                    <Text variant="medium" styles={textStyles}>
                        To use this app, you need to install the Agile Alfred app in Microsoft Teams.
                        This app is designed to work within the Microsoft Teams environment.
                    </Text>
                    <Text variant="large" styles={sectionHeadingStyles}>
                        Overview
                    </Text>
                    <Text variant="medium" styles={textStyles}>
                        Introducing a powerful and intuitive estimation tool built for Microsoft Teams! Designed with agile teams in mind, this app seamlessly integrates with Teams to support planning, estimation, and collaboration in real time. It's the ultimate tool for project leaders, Scrum masters, and team members aiming to achieve accuracy, transparency, and efficiency in project planning. From beginners to experts, every user can experience a smoother, smarter way to manage estimates, enabling your team to tackle any project with precision and confidence.
                    </Text>
                    <Text variant="large" styles={sectionHeadingStyles}>
                        Key Features
                    </Text>
                    <ul style={listStyles}>
                        <li>Planning Poker Sessions: Create and manage planning poker sessions directly within Teams. Easily invite other Scrum team members and start sessions within meetings for streamlined collaboration.</li>
                        <li>Effortless Voting Process: Enable team members to participate in voting by selecting a private numbered card. Monitor voting status in real-time, maintaining privacy until all votes are submitted.</li>
                        <li>Customizable Estimation Cards: Use pre-defined story points (Fibonacci), hours, t-shirt sizes, or create your custom set of cards. The app adapts to your preferred estimation method, fitting your team's unique style and needs.</li>
                        <li>Cross-Platform Compatibility: Enjoy seamless functionality with support for light and dark mode to enhance visibility and comfort for all users.</li>
                        <li>Voting Results Transparency: Once all votes are cast, the app displays voting results publicly, fostering discussion and enabling teams to reach a consensus effortlessly.</li>
                    </ul>
                    <Text variant="large" styles={sectionHeadingStyles}>
                        Unique Value Proposition within Teams
                    </Text>
                    <Text variant="medium" styles={textStyles}>
                        This app transforms estimation by aligning directly with Microsoft Teams' collaborative ecosystem. Without the need for external tools, your team can handle all estimation tasks inside Teams, enhancing efficiency and reducing the friction of switching apps. It supports your team's native workflow, making task estimates both accurate and easy to reach, and fosters team alignment with built-in transparency.
                    </Text>
                    <Stack horizontal tokens={{ childrenGap: 20 }} styles={linkContainerStyles}>
                        <Link onClick={() => navigate('/gettingstarted')} styles={linkStyles}>Getting Started</Link>
                        <Link onClick={() => navigate('/privacy')} styles={linkStyles}>Privacy Policy</Link>
                        <Link onClick={() => navigate('/termsofuse')} styles={linkStyles}>Terms of Use</Link>
                        <Link onClick={() => navigate('/contact')} styles={linkStyles}>Contact Us</Link>
                    </Stack>
                </Stack>
            </Stack>
        </ScrollablePane>
    );
};

// Styles
const scrollablePaneStyles = {
    root: {
        height: '100vh',
        width: '100vw',
        position: 'absolute' as 'absolute',
    },
};

const stackStyles = {
    root: {
        backgroundColor: '#f4f4f4',
        padding: '20px',
    },
};

const stackTokens: IStackTokens = { childrenGap: 15 };

const contentStyles = {
    root: {
        width: '800px',
        padding: '40px',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    },
};

const contentTokens: IStackTokens = { childrenGap: 20 };

const headingStyles = {
    root: {
        fontWeight: FontWeights.semibold,
        color: '#333',
        textAlign: 'center',
        marginBottom: '10px',
    },
};

const subHeadingStyles = {
    root: {
        fontWeight: FontWeights.regular,
        color: '#0078d4',
        textAlign: 'center',
        marginBottom: '20px',
    },
};

const sectionHeadingStyles = {
    root: {
        fontWeight: FontWeights.semibold,
        color: '#333',
        marginTop: '20px',
        marginBottom: '10px',
    },
};

const textStyles = {
    root: {
        color: '#666',
        lineHeight: '1.6',
        textAlign: 'left',
    },
};

const listStyles = {
    marginTop: '10px',
    marginBottom: '10px',
    paddingLeft: '20px',
    color: '#666',
    lineHeight: '1.6',
};

const linkContainerStyles = {
    root: {
        justifyContent: 'center',
        marginTop: '30px',
    },
};

const linkStyles = {
    root: {
        fontSize: '16px',
        color: '#0078d4',
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline',
        },
    },
};

export default HomeScreen;

import React from "react";
import { Stack, Text, DefaultButton, Label, FontWeights } from '@fluentui/react';
import { Overflow } from "@fluentui/react-components";
/**
 * This component is used to display the required
 * privacy policy statement which can be found in a
 * link in the about tab.
 */
class Privacy extends React.Component {
  render() {
    return (
      <Stack
        verticalAlign="center"
        horizontalAlign="center"
        styles={stackStyles}
        tokens={stackTokens}
      >
        <Stack
          styles={contentStyles}
          tokens={contentTokens}
        >
          <Text variant="xxLarge" styles={headingStyles}>
            Privacy Policy
          </Text>
          <Text variant="small" styles={lastUpdatedStyles}>
            Last Updated: June 18th, 2024
          </Text>

          <Label styles={subheadingStyles}>Introduction</Label>
          <Text variant="medium" styles={textStyles}>
            Agile Alfred (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your
            personal information is collected, used, and disclosed by Agile Alfred.
            This Privacy Policy applies to our app, and its associated subdomains (collectively, our “Service”) alongside
            our application, Agile Alfred. By accessing or using our Service, you signify that you have read, understood, and
            agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy
            Policy and our Terms of Service.
          </Text>

          <Label styles={subheadingStyles}>Definitions and Key Terms</Label>
          <Text variant="medium" styles={textStyles}>
            To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are
            referenced, they are strictly defined as:
          </Text>
          <ul>
            <li>Cookie: a small amount of data generated by a website and saved by your web browser. It is used to
              identify your browser, provide analytics, remember information about you such as your language
              preference or login information.
            </li>
            <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Techbase SLR, (Str.
              Ghioceilor 101A, Zurbaua, Ilfov), that is responsible for your information under this Privacy Policy.
            </li>
            <li>Country: where Agile Alfred or the owners/founders of Agile Alfred are based, in this case, Romania.</li>
            <li>Customer: refers to the company, organization, or person that signs up to use the Agile Alfred Service to
              manage the relationships with your consumers or service users.
            </li>
            <li>Device: any internet-connected device such as a phone, tablet, computer, or any other device that can
              be used to visit Agile Alfred and use the services.
            </li>
            <li>IP address: Every device connected to the Internet is assigned a number known as an Internet protocol
              (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be
              used to identify the location from which a device is connecting to the Internet.
            </li>
            <li>Personnel: refers to those individuals who are employed by Agile Alfred or are under contract to perform a
              service on behalf of one of the parties.
            </li>
            <li>Personal Data: any information that directly, indirectly, or in connection with other information —
              including a personal identification number — allows for the identification or identifiability of a natural
              person.
            </li>
            <li>Service: refers to the service provided by Agile Alfred as described in the relative terms (if available) and on
              this platform.
            </li>
            <li>Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and
              others who provide our content or whose products or services we think may interest you.
            </li>
            <li>App/Application: Agile Alfred app, refers to the SOFTWARE PRODUCT identified above.</li>
            <li>You: a person or entity that is registered with Agile Alfred to use the Services.</li>
          </ul>

          <Label styles={subheadingStyles}>Information Automatically Collected</Label>
          <Text variant="medium" styles={textStyles}>
            There is some information like your Internet Protocol (IP) address and/or browser and device characteristics
            — is collected automatically when you visit our platform. This information may be used to connect your
            computer to the Internet. Other information collected automatically could be a login, e-mail address,
            password, computer and connection information such as browser plug-in types and versions and time zone
            setting, operating systems and platforms, purchase history, (we sometimes aggregate with similar
            information from other Users), the full Uniform Resource Locator (URL) clickstream to, through, and from our
            app that may include date and time; cookie number; parts of the site you viewed or searched for; and the
            phone number you used to call our Customer Services. We may also use browser data such as cookies,
            Flash cookies (also known as Flash Local Shared Objects), or similar data on certain parts of our app for
            fraud prevention and other purposes. During your visits, we may use software tools such as JavaScript to
            measure and collect session information including page response times, download errors, length of visits to
            certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used
            to browse away from the page. We may also collect technical information to help us identify your device for
            fraud prevention and diagnostic purposes.
            We automatically collect certain information when you visit, use or navigate the platform. This information
            does not reveal your specific identity (like your name or contact information) but may include device and
            usage information, such as your IP address, browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location, information about who and when you use our
            and other technical information. This information is primarily needed to maintain the security and operation of
            our platform, and for our internal analytics and reporting purposes.
          </Text>

          <Label styles={subheadingStyles}>Personnel</Label>
          <Text variant="medium" styles={textStyles}>
            If you are a Agile Alfred worker or applicant, we collect information you voluntarily provide to us. We use the
            information collected for Human Resources purposes in order to administer benefits to workers and screen
            applicants.
            You may contact us in order to (1) update or correct your information, (2) change your preferences with
            respect to communications and other information you receive from us, or (3) receive a record of the
            information we have relating to you. Such updates, corrections, changes, and deletions will have no effect on
            other information that we maintain, or information that we have provided to third parties in accordance with
            this Privacy Policy prior to such update, correction, change, or deletion.
          </Text>

          <Label styles={subheadingStyles}>Sale of Business</Label>
          <Text variant="medium" styles={textStyles}>
            We reserve the right to transfer information to a third party in the event of a sale, merger or other transfer of
            all or substantially all of the assets of Agile Alfred or any of its Corporate Affiliates (as defined herein), or that
            portion of Agile Alfred or any of its Corporate Affiliates to which the Service relates, or in the event that we
            discontinue our business or file a petition or have filed against us a petition in bankruptcy, reorganization or
            similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.
          </Text>

          <Label styles={subheadingStyles}>Affiliates</Label>
          <Text variant="medium" styles={textStyles}>
            We may disclose information (including personal information) about you to our Corporate Affiliates. For
            purposes of this Privacy Policy, "Corporate Affiliate" means any person or entity which directly or indirectly
            controls, is controlled by, or is under common control with Agile Alfred, whether by ownership or otherwise. Any
            information relating to you that we provide to our Corporate Affiliates will be treated by those Corporate
            Affiliates in accordance with the terms of this Privacy Policy.
          </Text>

          <Label styles={subheadingStyles}>Governing Law</Label>
          <Text variant="medium" styles={textStyles}>
            This Privacy Policy is governed by the laws of Romania without regard to its conflict of laws provision. You
            consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between the
            parties under or in connection with this Privacy Policy except for those individuals who may have rights to
            make claims under Privacy Shield, or the Swiss-US framework.
            The laws of Romania, excluding its conflicts of law rules, shall govern this Agreement and your use of the
            app. Your use of the app may also be subject to other local, state, national, or international laws.
          </Text>

          <Label styles={subheadingStyles}>Your Consent</Label>
          <Text variant="medium" styles={textStyles}>
            We've updated our Privacy Policy to provide you with complete transparency into what is being set when you
            visit our site and how it's being used. By using our Agile Alfred, registering an account, or making a purchase, you
            hereby consent to our Privacy Policy and agree to its terms.
          </Text>

          <Label styles={subheadingStyles}>Links to Other Websites</Label>
          <Text variant="medium" styles={textStyles}>
            This Privacy Policy applies only to the Services. The Services may contain links to other websites not
            operated or controlled by Agile Alfred. We are not responsible for the content, accuracy or opinions expressed in
            such websites, and such websites are not investigated, monitored or checked for accuracy or completeness
            by us. Please remember that when you use a link to go from the Services to another website, our Privacy
            Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a
            link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own
            cookies or other methods to collect information about you.
          </Text>

          <Label styles={subheadingStyles}>Cookies</Label>
          <Text variant="medium" styles={textStyles}>
            Agile Alfred uses "Cookies" to identify the areas of our app that you have visited. A Cookie is a small piece of data
            stored on your computer or mobile device by your web browser. We use Cookies to enhance the
            performance and functionality of our app but are non-essential to their use. However, without these cookies,
            certain functionality like videos may become unavailable or you would be required to enter your login details
            every time you visit the app as we would not be able to remember that you had logged in previously. Most
            web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be
            able to access functionality on our app correctly or at all. We never place Personally Identifiable Information
            in Cookies.
          </Text>

          <Label styles={subheadingStyles}>Blocking and Disabling Cookies and Similar Technologies</Label>
          <Text variant="medium" styles={textStyles}>
            Wherever you're located you may also set your browser to block cookies and similar technologies, but this
            action may block our essential cookies and prevent our app from functioning properly, and you may not be
            able to fully utilize all of its features and services. You should also be aware that you may also lose some
            saved information (e.g. saved login details, site preferences) if you block cookies on your browser. Different
            browsers make different controls available to you. Disabling a cookie or category of cookie does not delete
            the cookie from your browser, you will need to do this yourself from within your browser, you should visit your
            browser's help menu for more information.
          </Text>

          <Label styles={subheadingStyles}>Kids' Privacy</Label>
          <Text variant="medium" styles={textStyles}>
            We do not address anyone under the age of 13. We do not knowingly collect personally identifiable
            information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your
            child has provided Us with Personal Data, please contact Us. If We become aware that We have collected
            Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to
            remove that information from Our servers.
          </Text>

          <Label styles={subheadingStyles}>Changes To Our Privacy Policy</Label>
          <Text variant="medium" styles={textStyles}>
            We may change our Service and policies, and we may need to make changes to this Privacy Policy so that
            they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for
            example, through our Service) before we make changes to this Privacy Policy and give you an opportunity to
            review them before they go into effect. Then, if you continue to use the Service, you will be bound by the
            updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete
            your account.
          </Text>

          <Label styles={subheadingStyles}>Third-Party Services</Label>
          <Text variant="medium" styles={textStyles}>
            We may display, include or make available third-party content (including data, information, applications and
            other products services) or provide links to third-party websites or services ("Third-Party Services").
            You acknowledge and agree that Agile Alfred shall not be responsible for any Third-Party Services, including their
            accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other
            aspect thereof. Agile Alfred does not assume and shall not have any liability or responsibility to you or any other
            person or entity for any Third-Party Services.
            Third-Party Services and links thereto are provided solely as a convenience to you and you access and use
            them entirely at your own risk and subject to such third parties' terms and conditions.
          </Text>

          <Label styles={subheadingStyles}>Tracking Technologies</Label>
          <Text variant="medium" styles={textStyles}>
            <ul>
              <li>Cookies: We use Cookies to enhance the performance and functionality of our platform but are non-essential to
                their use. However, without these cookies, certain functionality like videos may become unavailable or
                you would be required to enter your login details every time you visit the platform as we would not be
                able to remember that you had logged in previously.
              </li>
              <li>Local Storage: Local Storage sometimes known as DOM storage, provides web apps with methods and protocols for
                storing client-side data. Web storage supports persistent data storage, similar to cookies but with a
                greatly enhanced capacity and no information stored in the HTTP request header.
              </li>
              <li>Sessions: We use "Sessions" to identify the areas of our platform that you have visited. A Session is a small piece
                of data stored on your computer or mobile device by your web browser.
              </li>
            </ul>
          </Text>

          <Label styles={subheadingStyles}>Information about General Data Protection Regulation (GDPR)</Label>
          <Text variant="medium" styles={textStyles}>
            We may be collecting and using information from you if you are from the European Economic Area (EEA),
            and in this section of our Privacy Policy we are going to explain exactly how and why is this data collected,
            and how we maintain this data under protection from being replicated or used in the wrong way.
          </Text>

          <Label styles={subheadingStyles}>What is GDPR?</Label>
          <Text variant="medium" styles={textStyles}>
            GDPR is an EU-wide privacy and data protection law that regulates how EU residents' data is protected by
            companies and enhances the control the EU residents have, over their personal data.
            The GDPR is relevant to any globally operating company and not just the EU-based businesses and EU
            residents. Our customers’ data is important irrespective of where they are located, which is why we have
            implemented GDPR controls as our baseline standard for all our operations worldwide.
          </Text>

          <Label styles={subheadingStyles}>What is Personal Data?</Label>
          <Text variant="medium" styles={textStyles}>
            Any data that relates to an identifiable or identified individual. GDPR covers a broad spectrum of information
            that could be used on its own, or in combination with other pieces of information, to identify a person.
            Personal data extends beyond a person’s name or email address. Some examples include financial
            information, political opinions, genetic data, biometric data, IP addresses, physical address, sexual
            orientation, and ethnicity.
          </Text>

          <Label styles={subheadingStyles}>The Data Protection Principles</Label>
          <Text variant="medium" styles={textStyles}>
            The Data Protection Principles include requirements such as:
            <ul>
              <li>Personal data collected must be processed in a fair, legal, and transparent way and should only be
                used in a way that a person would reasonably expect.
              </li>
              <li>Personal data should only be collected to fulfil a specific purpose and it should only be used for that
                purpose. Organizations must specify why they need the personal data when they collect it.
              </li>
              <li>Personal data should be held no longer than necessary to fulfil its purpose.
              </li>
              <li>People covered by the GDPR have the right to access their own personal data. They can also request a
                copy of their data, and that their data be updated, deleted, restricted, or moved to another organization.
              </li>
            </ul>
          </Text>

          <Label styles={subheadingStyles}>Why is GDPR Important?</Label>
          <Text variant="medium" styles={textStyles}>
            GDPR adds some new requirements regarding how companies should protect individuals' personal data that
            they collect and process. It also raises the stakes for compliance by increasing enforcement and imposing
            greater fines for breach. Beyond these facts it's simply the right thing to do. At Agile Alfred we strongly believe that
            your data privacy is very important and we already have solid security and privacy practices in place that go
            beyond the requirements of this new regulation.
          </Text>

          <Label styles={subheadingStyles}>Individual Data Subject's Rights - Data Access, Portability and Deletion</Label>
          <Text variant="medium" styles={textStyles}>
            We are committed to helping our customers meet the data subject rights requirements of GDPR. Agile Alfred
            processes or stores all personal data in fully vetted, DPA compliant vendors. We do store all conversation
            and personal data for up to 6 years unless your account is deleted. In which case, we dispose of all data in
            accordance with our Terms of Service and Privacy Policy, but we will not hold it longer than 60 days.
            We are aware that if you are working with EU customers, you need to be able to provide them with the ability
            to access, update, retrieve and remove personal data. We got you! We've been set up as self service from
            the start and have always given you access to your data and your customers' data. Our customer support
            team is here for you to answer any questions you might have about working with the API.
          </Text>

          <Label styles={subheadingStyles}>California Residents</Label>
          <Text variant="medium" styles={textStyles}>
            The California Consumer Privacy Act (CCPA) requires us to disclose categories of Personal Information we
            collect and how we use it, the categories of sources from whom we collect Personal Information, and the
            third parties with whom we share it, which we have explained above.
            We are also required to communicate information about rights California residents have under California law.
            You may exercise the following rights:
            <ul>
              <li>Right to Know and Access. You may submit a verifiable request for information regarding the: (1)
                categories of Personal Information we collect, use, or share; (2) purposes for which categories of
                Personal Information are collected or used by us; (3) categories of sources from which we collect
                Personal Information; and (4) specific pieces of Personal Information we have collected about you.
              </li>
              <li>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.
              </li>
              <li>Right to Delete. You may submit a verifiable request to close your account and we will delete Personal
                Information about you that we have collected.
              </li>
              <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
              </li>
            </ul>
            If you make a request, we have one month to respond to you. If you would like to exercise any of these
            rights, please contact us.
            We do not sell the Personal Information of our users.
            For more information about these rights, please contact us.
          </Text>

          <Label styles={subheadingStyles}>California Online Privacy Protection Act (CalOPPA)</Label>
          <Text variant="medium" styles={textStyles}>
            CalOPPA requires us to disclose categories of Personal Information we collect and how we use it, the
            categories of sources from whom we collect Personal Information, and the third parties with whom we share
            it, which we have explained above.
            CalOPPA users have the following rights:
            <ul>
              <li>Right to Know and Access. You may submit a verifiable request for information regarding the: (1)
                categories of Personal Information we collect, use, or share; (2) purposes for which categories of
                Personal Information are collected or used by us; (3) categories of sources from which we collect
                Personal Information; and (4) specific pieces of Personal Information we have collected about you.
              </li>
              <li>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.
              </li>
              <li>Right to Delete. You may submit a verifiable request to close your account and we will delete Personal
                Information about you that we have collected.
              </li>
              <li>Right to request that a business that sells a consumer's personal data, not sell the consumer's personal
                data.
              </li>
            </ul>
            If you make a request, we have one month to respond to you. If you would like to exercise any of these
            rights, please contact us.
            We do not sell the Personal Information of our users.
            For more information about these rights, please contact us.
          </Text>

          <Label styles={subheadingStyles}>Contact Us</Label>
          <Text variant="medium" styles={textStyles}>
            Don't hesitate to contact us if you have any questions.
            <ul>
              <li>Via Email: alfred@techbase.ro</li>
            </ul>
          </Text>
        </Stack>
      </Stack>
    );
  }
}

// Fluent UI Styles
const stackStyles = {
  root: {
    minHeight: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '20px',
  },
};

const stackTokens = { childrenGap: 15 };

const contentStyles = {
  root: {
    maxHeight: '100vh',
    width: '800px',
    padding: '40px',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    overflowY: 'scroll'
  },
};

const contentTokens = { childrenGap: 0 };

const headingStyles = {
  root: {
    fontWeight: FontWeights.semibold,
    color: '#333',
    textAlign: 'center',
    marginBottom: '20px',
  },
};

const lastUpdatedStyles = {
  root: {
    color: '#777',
    textAlign: 'center',
    marginBottom: '40px',
  },
};

const subheadingStyles = {
  root: {
    fontWeight: FontWeights.semibold,
    color: '#555',
    marginTop: '15px',
    marginBottom: '5px',
  },
};

const textStyles = {
  root: {
    color: '#666',
    lineHeight: '1.6',
    marginTop: '1px',
    marginBottom: '25px'
  },
};

export default Privacy;

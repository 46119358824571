import React from "react";
import * as microsoftTeams from "@microsoft/teams-js";


class ConfigTab extends React.Component {
  render() {
    // Initialize the Microsoft Teams SDK
    microsoftTeams.app.initialize().then(() => {
     
      microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {

        microsoftTeams.pages.config
          .setConfig({
            suggestedDisplayName: "Alfred",
            contentUrl: `${window.location.origin}/index.html#mainapp`,
          })
          .then(() => {
            saveEvent.notifySuccess();
          });
      });

      
      microsoftTeams.pages.config.setValidityState(true);

      // Hide the loading indicator.
      microsoftTeams.app.notifySuccess();
    });

    return (
      <div>
        <h3>Welcome to Alfred</h3>
          The last Estimation Tool you will need!
      </div>
    );
  }
}

export default ConfigTab;
import * as React from 'react';
import { ITeamsUser } from './utils/usePresence';
import AttendenceList from './AttendenceList';
import VotingCards from './VotingCards';
import { PlayingCardsRegular } from "@fluentui/react-icons";
import CardConfigurator from './CardConfigurator';
import { useMemo } from 'react';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { Button, Text, Card, tokens } from '@fluentui/react-components';

interface IVotingScreenProps {
    onlineUsers: ITeamsUser[],
    appState: string,
    cardConfig: string[],
    onShowResults: () => void,
    onCastVote: (vote: string, hasVoted: boolean) => void,
    changeCardDeck: (cardConfig: string[]) => void
}

const VotingScreen = ({onlineUsers, appState, cardConfig, onShowResults, onCastVote, changeCardDeck }: IVotingScreenProps) => {    
    const sortedOnlineUsers = useMemo(() => {
        return [...onlineUsers].sort((a, b) => {
            if (a.hasVoted === b.hasVoted) return 0;
            return a.hasVoted ? 1 : -1;
        });
    }, [onlineUsers]);
      
    return (
        <Stack verticalFill styles={{ root: { height: '100vh' } }}>
            {/* Title Section */}
            <StackItem grow={0} style={{ maxHeight: '5%', padding: '10px', textAlign: 'center' }}>
                <Text size={500} weight="bold" style={{ color: tokens.colorNeutralForeground1 }}>Place your vote</Text>
            </StackItem>

            {/* Card Configurator Section */}
            <StackItem grow={0} style={{ padding: '10px 16px' }}>
                <Stack horizontal horizontalAlign="end">
                    <CardConfigurator 
                        cardValues={cardConfig} 
                        onCardConfigurationChanged={changeCardDeck}
                    />
                </Stack>
            </StackItem>

            {/* Cards Section */}
            <StackItem grow={0} style={{ maxHeight: '50%', overflowY: 'auto' }}>
                <Card>                    
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                        <VotingCards onCastVote={onCastVote} cardValues={cardConfig} />
                    </div>
                </Card>
            </StackItem>

            {/* Reveal Votes Section */}
            <StackItem grow={0} style={{ padding: '10px 16px' }}>
                <Stack horizontal horizontalAlign="center">
                    <Button appearance="primary" onClick={onShowResults} icon={<PlayingCardsRegular />}>
                        Reveal Votes
                    </Button>
                </Stack>
            </StackItem>

            {/* Attendance List Section */}
            <StackItem grow={1} style={{ overflowY: 'auto' }}>
                <Card>                
                    <AttendenceList appState={appState} onlineUsers={sortedOnlineUsers} />
                </Card>
            </StackItem>
        </Stack>
    );
};

export default VotingScreen;


import { useEffect, useState, useCallback, useRef } from "react";
import { LiveState } from "@microsoft/live-share";
import { IAppState } from "./useSharedObjects";
import { CardValues } from "../common/CardValues";

export enum AppStateEnum {
    config = "config",
    voting = "voting",
    results = "results",
}
/**
 *
 * @param {LiveState<IAppState>} pokerState LiveState object
 * @param {Function} onStateChanged callback when state changed
 * @returns pokerStateStarted, state, userStoryId, onStartWaiting, onStartCosting, onStartDiscussion
 */
export const useAppState = (appState: LiveState<IAppState> | undefined , onStateChanged: (newState: string) => void, onStateDeckConfigChanged: (newDeck: string[]) => void) => {
    const initializeStartedRef = useRef(false);
    const [appStarted, setStarted] = useState(false);
    const [state, setState] = useState<string>();
    const [deckConfiguration, setDeckConfiguration] = useState<string[]>();

    const changeAppState = useCallback(
        (newState: string) => {
            if (!appState) return;
           
            appState.set({
                state: newState, 
                deckConfiguration: deckConfiguration});
        },
        [appState]
    );

    const changeAppCards = useCallback(
        (newDeck: string[]) => {
            if (!appState) return;
           
            appState.set({
                state: state, 
                deckConfiguration: newDeck});
        },
        [appState]
    );
    
   
    useEffect(() => {
        if (
            !appState ||
            appState.isInitialized ||
            initializeStartedRef.current
        )
            return;
        console.log("usePokerState: initializing poker state");
        initializeStartedRef.current = true;
        appState.on("stateChanged", (liveShareState: IAppState) => {
            debugger;
            if(liveShareState.state !== state){
                onStateChanged(!liveShareState?.state? AppStateEnum.voting : liveShareState?.state);
                setState(liveShareState.state);
            }

            if(liveShareState.deckConfiguration !== deckConfiguration){
                onStateDeckConfigChanged(!liveShareState?.deckConfiguration? CardValues.Hours : liveShareState.deckConfiguration);
                setDeckConfiguration(!liveShareState?.deckConfiguration? CardValues.Hours : liveShareState.deckConfiguration);
            }
            
        });
        
        appState
            .initialize(
                {
                    state: AppStateEnum.voting, 
                    deckConfiguration: CardValues.Hours
                })
            .then(() => {
                setStarted(true);
            })
            .catch((error) => console.error(error));
    }, [appState]);

    return {
        appStarted,
        state: !state? AppStateEnum.voting : state,
        deckConfig: !deckConfiguration? CardValues.Hours: deckConfiguration,
        changeState: changeAppState,
        changeCards: changeAppCards
    };
};

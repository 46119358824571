import { DefaultEffects } from '@fluentui/react';
import { NeutralColors, SharedColors  } from '@fluentui/theme';

interface iVotingCardProps {
    value: string,
    isSelected: boolean,
    cardValueType:string,
    onCardClicked: (vote: string) => void,
}

export enum CardValueType {
  low = "low",
  medium = "medium",
  high = "high",
  neutral = "neutral"
}

const VotingCard = ({value, isSelected, cardValueType, onCardClicked}: iVotingCardProps) =>{
  
  
  const getCardTextColor = function(){
    
    switch(cardValueType){
      case  CardValueType.low:
        return SharedColors.blue10;
      case CardValueType.medium:
        return SharedColors.blue10;
      case  CardValueType.high:
        return SharedColors.blue10;
      default:
        return SharedColors.blue10;
    }
  }

  const cardStle = {
    color: getCardTextColor(),
    height:"6em",
    backgroundColor: isSelected? NeutralColors.gray30: NeutralColors.white,
    width:"3.5em",
    margin:"0.5em",    
    boxShadow: isSelected? DefaultEffects.elevation16: DefaultEffects.elevation4,
    borderRadius: '5px',
    display:"flex",
    cursor: 'pointer',
    justifyContent: "space-between",
    padding: "1px",
    "flex-direction": "column"
}
const mainNumber = {
  fontWeight: "bold",
  alignContent: "center",
  fontSize: "large",
  "text-align": "center"
}

const topLeftStyle = {
  padding: "0.1em",
  fontSize: "small"
}

const bottomRight = {
  alignSelf: "end",
  transform: "rotate(180deg)",
  fontSize: "x-small"
}

  return (
    <>
      <div style={cardStle} className='edgarCard' onClick={()=>{onCardClicked(value)}}> 
        <div style={topLeftStyle}>
          {value}
        </div>
        <div style={mainNumber}>
          {value}
        </div>
        <div style={bottomRight}>
          {value}
        </div>
      </div>
    </>
  );
};

export default VotingCard;
import React from 'react';
import { ITeamsUser } from './utils/usePresence';
import AttendenceList from './AttendenceList';
import { Button, Text, Card, CardHeader, tokens } from '@fluentui/react-components';
import { ArrowResetRegular } from "@fluentui/react-icons";
import { Stack, StackItem } from '@fluentui/react/lib/Stack';

interface IResultsScreenProps {
    onlineUsers: ITeamsUser[],
    appState: string,
    onResetVoting: () => void,
}

const ResultsScreen: React.FC<IResultsScreenProps> = ({ onlineUsers, appState, onResetVoting }) => {
    const usersThatVoted = onlineUsers.filter(user => user.hasVoted);

    return (
        <Stack verticalFill styles={{ root: { height: '100vh' } }}>
            {/* Title Section */}
            <StackItem grow={0} style={{ maxHeight: '5%', padding: '10px', textAlign: 'center' }}>
                <Text size={500} weight="bold" style={{ color: tokens.colorNeutralForeground1 }}>Voting Results</Text>
            </StackItem>

            {/* Reset Voting Button Section */}
            <StackItem grow={0} style={{ padding: '10px', textAlign: 'center' }}>                
                <Button appearance="primary" onClick={onResetVoting} icon={<ArrowResetRegular />}>
                    Reset Voting
                </Button>
            </StackItem>

            {/* Voting Results Section */}
            <StackItem grow={1} style={{ overflowY: 'auto' }}>
                <Card>
                    <CardHeader 
                        header={
                            <Text 
                                weight="semibold" 
                                style={{ color: tokens.colorNeutralForeground1 , textAlign: 'center'}}>
                                    {`${usersThatVoted.length} votes and ${onlineUsers.length - usersThatVoted.length} abstentions`}
                            </Text>}
                    />
                    <AttendenceList appState={appState} onlineUsers={onlineUsers} />
                </Card>
            </StackItem>
        </Stack>
    );
};

export default ResultsScreen;

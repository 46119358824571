import React from "react";
import { Stack, Text, DefaultButton, Label, FontWeights } from '@fluentui/react';
import { Overflow } from "@fluentui/react-components";
/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
class TermsOfUse extends React.Component {
  render() {
    return (
      <Stack
        verticalAlign="center"
        horizontalAlign="center"
        styles={stackStyles}
        tokens={stackTokens}
      >
        <Stack
          styles={contentStyles}
          tokens={contentTokens}
        >
          <Text variant="xxLarge" styles={headingStyles}>
            Terms of Use
          </Text>
          <Text variant="small" styles={lastUpdatedStyles}>
            Last Updated: August 28th, 2024
          </Text>

          <Label styles={subheadingStyles}>General Terms</Label>
          <Text variant="medium" styles={textStyles}>
            By accessing and placing an order with Agile Alfred, you confirm that you are in agreement with and bound by the
            terms of service contained in the Terms & Conditions outlined below. These terms apply to the entire website
            and any email or other type of communication between you and Agile Alfred.
            Under no circumstances shall Agile Alfred team be liable for any direct, indirect, special, incidental or
            consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or the
            inability to use, the materials on this site, even if Agile Alfred team or an authorized representative has been
            advised of the possibility of such damages. If your use of materials from this site results in the need for
            servicing, repair or correction of equipment or data, you assume any costs thereof.
            Agile Alfred will not be responsible for any outcome that may occur during the course of usage of our resources.
            We reserve the rights to change prices and revise the resources usage policy in any moment.
          </Text>

          <Label styles={subheadingStyles}>License</Label>
          <Text variant="medium" styles={textStyles}>
            Agile Alfred grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use
            the app strictly in accordance with the terms of this Agreement.
            These Terms & Conditions are a contract between you and Agile Alfred (referred to in these Terms & Conditions
            as "Agile Alfred", "us", "we" or "our"), the provider of the Agile Alfred website and the services accessible from the Agile Alfred
            website (which are collectively referred to in these Terms & Conditions as the "Agile Alfred Service").
            You are agreeing to be bound by these Terms & Conditions. If you do not agree to these Terms &
            Conditions, please do not use the Agile Alfred Service. In these Terms & Conditions, "you" refers both to you as an
            individual and to the entity you represent. If you violate any of these Terms & Conditions, we reserve the
            right to cancel your account or block access to your account without notice.
          </Text>

          <Label styles={subheadingStyles}>Definitions and Key Terms</Label>
          <Text variant="medium" styles={textStyles}>
            To help explain things as clearly as possible in this Terms & Conditions, every time any of these terms are
            referenced, they are strictly defined as:
          </Text>
          <ul>
            <li>Cookie: a small amount of data generated by a website and saved by your web browser. It is used to
              identify your browser, provide analytics, remember information about you such as your language
              preference or login information.
            </li>
            <li>Company: when this terms mention “Company,” “we,” “us,” or “our,” it refers to Techbase SLR, (Str.
              Ghioceilor 101A, Zurbaua, Ilfov), that is responsible for your information under this Terms & Conditions.
            </li>
            <li>Country: where Agile Alfred or the owners/founders of Agile Alfred are based, in this case, Romania.</li>
            <li>Device: any internet-connected device such as a phone, tablet, computer, or any other device that can
              be used to visit Agile Alfred and use the services.
            </li>
            <li>Service: refers to the service provided by Agile Alfred as described in the relative terms (if available) and on
              this platform.
            </li>
            <li>Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and
              others who provide our content or whose products or services we think may interest you.
            </li>
            <li>App/Application: Agile Alfred app, refers to the SOFTWARE PRODUCT identified above.</li>
            <li>You: a person or entity that is registered with Agile Alfred to use the Services.</li>
          </ul>

          <Label styles={subheadingStyles}>Restrictions</Label>
          <Text variant="medium" styles={textStyles}>
            You agree not to, and you will not permit others to:
            <ul>
              <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise
                commercially exploit the app or make the platform available to any third party.
              </li>
              <li>Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part
                of the app.
              </li>
              <li>Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of
                Agile Alfred or its affiliates, partners, suppliers, or licensors of the app.
              </li>
            </ul>
          </Text>

          <Label styles={subheadingStyles}>Return and Refund Policy</Label>
          <Text variant="medium" styles={textStyles}>
            Thanks for shopping at Agile Alfred. We appreciate the fact that you like to buy the stuff we build. We also want to
            make sure you have a rewarding experience while you’re exploring, evaluating, and purchasing our products.
            As with any shopping experience, there are terms and conditions that apply to transactions at Agile Alfred. We’ll be
            as brief as our attorneys will allow. The main thing to remember is that by placing an order or making a
            purchase at Agile Alfred, you agree to the terms along with Agile Alfred’s Privacy Policy.
            If, for any reason, You are not completely satisfied with any good or service that we provide, don't hesitate to
            contact us and we will discuss any of the issues you are going through with our product.
          </Text>

          <Label styles={subheadingStyles}>Your Suggestions</Label>
          <Text variant="medium" styles={textStyles}>
            Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you
            to Agile Alfred with respect to the app shall remain the sole and exclusive property of Agile Alfred.
            Agile Alfred shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any
            way without any credit or any compensation to you.
          </Text>

          <Label styles={subheadingStyles}>Your Consent</Label>
          <Text variant="medium" styles={textStyles}>
            We've updated our Terms & Conditions to provide you with complete transparency into what is being set
            when you visit our site and how it's being used. By using our app, registering an account, or making a
            purchase, you hereby consent to our Terms & Conditions.
          </Text>

          <Label styles={subheadingStyles}>Links to Other Websites</Label>
          <Text variant="medium" styles={textStyles}>
            This Terms & Conditions applies only to the Services. The Services may contain links to other websites not
            operated or controlled by Agile Alfred. We are not responsible for the content, accuracy or opinions expressed in
            such websites, and such websites are not investigated, monitored or checked for accuracy or completeness
            by us. Please remember that when you use a link to go from the Services to another website, our Terms &
            Conditions are no longer in effect. Your browsing and interaction on any other website, including those that
            have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use
            their own cookies or other methods to collect information about you.
          </Text>

          <Label styles={subheadingStyles}>Cookies</Label>
          <Text variant="medium" styles={textStyles}>
            Agile Alfred uses "Cookies" to identify the areas of our app that you have visited. A Cookie is a small piece of data
            stored on your computer or mobile device by your web browser. We use Cookies to enhance the
            performance and functionality of our app but are non-essential to their use. However, without these cookies,
            certain functionality like videos may become unavailable or you would be required to enter your login details
            every time you visit the app as we would not be able to remember that you had logged in previously. Most
            web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be
            able to access functionality on our app correctly or at all. We never place Personally Identifiable Information
            in Cookies.
          </Text>

          <Label styles={subheadingStyles}>Changes To Our Terms & Conditions</Label>
          <Text variant="medium" styles={textStyles}>
            You acknowledge and agree that Agile Alfred may stop (permanently or temporarily) providing the Service (or any
            features within the Service) to you or to users generally at Agile Alfred’s sole discretion, without prior notice to you.
            You may stop using the Service at any time. You do not need to specifically inform Agile Alfred when you stop
            using the Service. You acknowledge and agree that if Agile Alfred disables access to your account, you may be
            prevented from accessing the Service, your account details or any files or other materials which is contained
            in your account.
          </Text>

          <Label styles={subheadingStyles}>Modifications to Our app</Label>
          <Text variant="medium" styles={textStyles}>
            Agile Alfred reserves the right to modify, suspend or discontinue, temporarily or permanently, the app or any
            service to which it connects, with or without notice and without liability to you.
          </Text>

          <Label styles={subheadingStyles}>Updates to Our app</Label>
          <Text variant="medium" styles={textStyles}>
            Agile Alfred may from time to time provide enhancements or improvements to the features/ functionality of the app,
            which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").
            Updates may modify or delete certain features and/or functionalities of the app. You agree that Agile Alfred has no
            obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or
            functionalities of the app to you.
            You further agree that all Updates will be (i) deemed to constitute an integral part of the app, and (ii) subject
            to the terms and conditions of this Agreement.
          </Text>

          <Label styles={subheadingStyles}>Third-Party Services</Label>
          <Text variant="medium" styles={textStyles}>
            We may display, include or make available third-party content (including data, information, applications and
            other products services) or provide links to third-party websites or services ("Third-Party Services").
            You acknowledge and agree that Agile Alfred shall not be responsible for any Third-Party Services, including their
            accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other
            aspect thereof. Agile Alfred does not assume and shall not have any liability or responsibility to you or any other
            person or entity for any Third-Party Services.
            Third-Party Services and links thereto are provided solely as a convenience to you and you access and use
            them entirely at your own risk and subject to such third parties' terms and conditions.
          </Text>

          <Label styles={subheadingStyles}>Term and Termination</Label>
          <Text variant="medium" styles={textStyles}>
            This Agreement shall remain in effect until terminated by you or Agile Alfred.
            Agile Alfred may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement
            with or without prior notice.
            This Agreement will terminate immediately, without prior notice from Agile Alfred, in the event that you fail to
            comply with any provision of this Agreement. You may also terminate this Agreement by deleting the app
            and all copies thereof from your computer.
            Upon termination of this Agreement, you shall cease all use of the app and delete all copies of the app from
            your computer.
            Termination of this Agreement will not limit any of Agile Alfred's rights or remedies at law or in equity in case of
            breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.
          </Text>

          <Label styles={subheadingStyles}>Copyright Infringement Notice</Label>
          <Text variant="medium" styles={textStyles}>
            If you are a copyright owner or such owner’s agent and believe any material on our app constitutes an
            infringement on your copyright, please contact us setting forth the following information: (a) a physical or
            electronic signature of the copyright owner or a person authorized to act on his behalf; (b) identification of the
            material that is claimed to be infringing; (c) your contact information, including your address, telephone
            number, and an email; (d) a statement by you that you have a good faith belief that use of the material is not
            authorized by the copyright owners; and (e) a statement that the information in the notification is
            accurate, and, under penalty of perjury you are authorized to act on behalf of the owner.
          </Text>

          <Label styles={subheadingStyles}>Indemnification</Label>
          <Text variant="medium" styles={textStyles}>
            You agree to indemnify and hold Agile Alfred and its parents, subsidiaries, affiliates, officers, employees, agents,
            partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due
            to or arising out of your: (a) use of the app; (b) violation of this Agreement or any law or regulation; or (c)
            violation of any right of a third party.
          </Text>

          <Label styles={subheadingStyles}>No Warranties</Label>
          <Text variant="medium" styles={textStyles}>
            The app is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of
            any kind. To the maximum extent permitted under applicable law, Agile Alfred, on its own behalf and on behalf of
            its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties,
            whether express, implied, statutory or otherwise, with respect to the app, including all implied warranties of
            merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out
            of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing,
            Agile Alfred provides no warranty or undertaking, and makes no representation of any kind that the app will meet
            your requirements, achieve any intended results, be compatible or work with any other software, apps,
            systems or services, operate without interruption, meet any performance or reliability standards or be error
            free or that any errors or defects can or will be corrected.
          </Text>

          <Label styles={subheadingStyles}>Limitation of Liability</Label>
          <Text variant="medium" styles={textStyles}>
            Notwithstanding any damages that you might incur, the entire liability of Agile Alfred and any of its suppliers under
            any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the
            amount actually paid by you for the app.
            To the maximum extent permitted by applicable law, in no event shall Agile Alfred or its suppliers be liable for any
            special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for
            loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of
            privacy arising out of or in any way related to the use of or inability to use the app, third-party software and/or
            third-party hardware used with the app, or otherwise in connection with any provision of this Agreement),
            even if Agile Alfred or any supplier has been advised of the possibility of such damages and even if the remedy
            fails of its essential purpose.
          </Text>

          <Label styles={subheadingStyles}>Severability</Label>
          <Text variant="medium" styles={textStyles}>
            If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and
            interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable
            law and the remaining provisions will continue in full force and effect.
            This Agreement, together with the Privacy Policy and any other legal notices published by Agile Alfred on the
            Services, shall constitute the entire agreement between you and Agile Alfred concerning the Services. If any
            provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such
            provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full
            force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of
            such term or any other term, and Agile Alfred’s failure to assert any right or provision under this Agreement shall
            not constitute a waiver of such right or provision. YOU AND Agile Alfred AGREE THAT ANY CAUSE OF ACTION
            ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER
            THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
            BARRED.
          </Text>

          <Label styles={subheadingStyles}>Waiver</Label>
          <Text variant="medium" styles={textStyles}>
            Except as provided herein, the failure to exercise a right or to require performance of an obligation under this
            Agreement shall not affect a party's ability to exercise such right or require such performance at any time
            thereafter nor shall be the waiver of a breach constitute waiver of any subsequent breach.
            No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under
            this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any
            right or power under this Agreement preclude further exercise of that or any other right granted herein. In the
            event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this
            Agreement shall govern.
          </Text>

          <Label styles={subheadingStyles}>Amendments to this Agreement</Label>
          <Text variant="medium" styles={textStyles}>
            Agile Alfred reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is
            material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a
            material change will be determined at our sole discretion.
            By continuing to access or use our app after any revisions become effective, you agree to be bound by the
            revised terms. If you do not agree to the new terms, you are no longer authorized to use Agile Alfred.
          </Text>

          <Label styles={subheadingStyles}>Entire Agreement</Label>
          <Text variant="medium" styles={textStyles}>
            The Agreement constitutes the entire agreement between you and Agile Alfred regarding your use of the app and
            supersedes all prior and contemporaneous written or oral agreements between you and Agile Alfred.
            You may be subject to additional terms and conditions that apply when you use or purchase other Agile Alfred's
            services, which Agile Alfred will provide to you at the time of such use or purchase.
          </Text>

          <Label styles={subheadingStyles}>Intellectual Property</Label>
          <Text variant="medium" styles={textStyles}>
            The app and its entire contents, features and functionality (including but not limited to all information,
            software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are
            owned by Agile Alfred, its licensors or other providers of such material and are protected by Romania and
            international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights
            laws. The material may not be copied, modified, reproduced, downloaded or distributed in any way, in whole
            or in part, without the express prior written permission of Agile Alfred, unless and except as is expressly provided
            in these Terms & Conditions. Any unauthorized use of the material is prohibited.
          </Text>

          <Label styles={subheadingStyles}>Agreement to Arbitrate</Label>
          <Text variant="medium" styles={textStyles}>
            This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A DISPUTE RELATING TO CLAIMS
            FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR
            OR Agile Alfred’s INTELLECTUAL PROPERTY RIGHTS. The term “dispute” means any dispute, action, or other
            controversy between you and Agile Alfred concerning the Services or this agreement, whether in contract,
            warranty, tort, statute, regulation, ordinance, or any other legal or equitable basis. “Dispute” will be given the
            broadest possible meaning allowable under law.
          </Text>

          <Label styles={subheadingStyles}>Notice of Dispute</Label>
          <Text variant="medium" styles={textStyles}>
            In the event of a dispute, you or Agile Alfred must give the other a Notice of Dispute, which is a written statement
            that sets forth the name, address, and contact information of the party giving it, the facts giving rise to the
            dispute, and the relief requested. You must send any Notice of Dispute via email to: info@techbase.ro.
            Agile Alfred will send any Notice of Dispute to you by mail to your address if we have it, or otherwise to your email
            address. You and Agile Alfred will attempt to resolve any dispute through informal negotiation within sixty (60)
            days from the date the Notice of Dispute is sent. After sixty (60) days, you or Agile Alfred may commence
            arbitration.
          </Text>

          <Label styles={subheadingStyles}>Binding Arbitration</Label>
          <Text variant="medium" styles={textStyles}>
            If you and Agile Alfred don’t resolve any dispute by informal negotiation, any other effort to resolve the dispute will
            be conducted exclusively by binding arbitration as described in this section. You are giving up the right to
            litigate (or participate in as a party or class member) all disputes in court before a judge or jury. The dispute
            shall be settled by binding arbitration in accordance with the commercial arbitration rules of the American
            Arbitration Association. Either party may seek any interim or preliminary injunctive relief from any court of
            competent jurisdiction, as necessary to protect the party’s rights or property pending the completion of
            arbitration. Any and all legal, accounting, and other costs, fees, and expenses incurred by the prevailing
            party shall be borne by the non-prevailing party.
          </Text>

          <Label styles={subheadingStyles}>Submissions and Privacy</Label>
          <Text variant="medium" styles={textStyles}>
            In the event that you submit or post any ideas, creative suggestions, designs, photographs, information,
            advertisements, data or proposals, including ideas for new or improved products, services, features,
            technologies or promotions, you expressly agree that such submissions will automatically be treated as
            non-confidential and non-proprietary and will become the sole property of Agile Alfred without any compensation
            or credit to you whatsoever. Agile Alfred and its affiliates shall have no obligations with respect to such
            submissions or posts and may use the ideas contained in such submissions or posts for any purposes in any
            medium in perpetuity, including, but not limited to, developing, manufacturing, and marketing products and
            services using such ideas.
          </Text>

          <Label styles={subheadingStyles}>Promotions</Label>
          <Text variant="medium" styles={textStyles}>
            Agile Alfred may, from time to time, include contests, promotions, sweepstakes, or other activities (“Promotions”)
            that require you to submit material or information concerning yourself. Please note that all Promotions may
            be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age
            and geographic location. You are responsible to read all Promotions rules to determine whether or not you
            are eligible to participate. If you enter any Promotion, you agree to abide by and to comply with all
            Promotions Rules.
            Additional terms and conditions may apply to purchases of goods or services on or through the Services,
            which terms and conditions are made a part of this Agreement by this reference.
          </Text>

          <Label styles={subheadingStyles}>Typographical Errors</Label>
          <Text variant="medium" styles={textStyles}>
            In the event a product and/or service is listed at an incorrect price or with incorrect information due to
            typographical error, we shall have the right to refuse or cancel any orders placed for the product and/or
            service listed at the incorrect price. We shall have the right to refuse or cancel any such order whether or not
            the order has been confirmed and your credit card charged. If your credit card has already been charged for
            the purchase and your order is canceled, we shall immediately issue a credit to your credit card account or
            other payment account in the amount of the charge.
          </Text>

          <Label styles={subheadingStyles}>Miscellaneous</Label>
          <Text variant="medium" styles={textStyles}>
            If for any reason a court of competent jurisdiction finds any provision or portion of these Terms & Conditions
            to be unenforceable, the remainder of these Terms & Conditions will continue in full force and effect. Any
            waiver of any provision of these Terms & Conditions will be effective only if in writing and signed by an
            authorized representative of Agile Alfred. Agile Alfred will be entitled to injunctive or other equitable relief (without the
            obligations of posting any bond or surety) in the event of any breach or anticipatory breach by you. Agile Alfred
            operates and controls the Agile Alfred Service from its offices in Romania. The Service is not intended for
            distribution to or use by any person or entity in any jurisdiction or country where such distribution or use
            would be contrary to law or regulation. Accordingly, those persons who choose to access the Agile Alfred Service
            from other locations do so on their own initiative and are solely responsible for compliance with local laws, if
            and to the extent local laws are applicable. These Terms & Conditions (which include and incorporate the
            Agile Alfred Privacy Policy) contains the entire understanding, and supersedes all prior understandings, between
            you and Agile Alfred concerning its subject matter, and cannot be changed or modified by you. The section
            headings used in this Agreement are for convenience only and will not be given any legal import.
          </Text>

          <Label styles={subheadingStyles}>Disclaimer</Label>
          <Text variant="medium" styles={textStyles}>
            Agile Alfred is not responsible for any content, code or any other imprecision.
            Agile Alfred does not provide warranties or guarantees.
            In no event shall Agile Alfred be liable for any special, direct, indirect, consequential, or incidental damages or any
            damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in
            connection with the use of the Service or the contents of the Service. The Company reserves the right to
            make additions, deletions, or modifications to the contents on the Service at any time without prior notice.
            The Agile Alfred Service and its contents are provided "as is" and "as available" without any warranty or
            representations of any kind, whether express or implied. Agile Alfred is a distributor and not a publisher of the
            content supplied by third parties; as such, Agile Alfred exercises no editorial control over such content and makes
            no warranty or representation as to the accuracy, reliability or currency of any information, content, service or
            merchandise provided through or accessible via the Agile Alfred Service. Without limiting the foregoing, Agile Alfred
            specifically disclaims all warranties and representations in any content transmitted on or in connection with
            the Agile Alfred Service or on sites that may appear as links on the Agile Alfred Service, or in the products provided as a
            part of, or otherwise in connection with, the Agile Alfred Service, including without limitation any warranties of
            merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or
            written information given by Agile Alfred or any of its affiliates, employees, officers, directors, agents, or the like will
            create a warranty. Price and availability information is subject to change without notice. Without limiting the
            foregoing, Agile Alfred does not warrant that the Agile Alfred Service will be uninterrupted, uncorrupted, timely, or
            error-free.
          </Text>

          <Label styles={subheadingStyles}>Contact Us</Label>
          <Text variant="medium" styles={textStyles}>
            Don't hesitate to contact us if you have any questions.
            <ul>
              <li>Via Email: alfred@techbase.ro</li>
            </ul>
          </Text>
        </Stack>
      </Stack>
    );
  }
}

// Fluent UI Styles
const stackStyles = {
  root: {
    minHeight: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '20px',
  },
};

const stackTokens = { childrenGap: 15 };

const contentStyles = {
  root: {
    maxHeight: '100vh',
    width: '800px',
    padding: '40px',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
    overflowY: 'scroll'
  },
};

const contentTokens = { childrenGap: 0 };

const headingStyles = {
  root: {
    fontWeight: FontWeights.semibold,
    color: '#333',
    textAlign: 'center',
    marginBottom: '20px',
  },
};

const lastUpdatedStyles = {
  root: {
    color: '#777',
    textAlign: 'center',
    marginBottom: '40px',
  },
};

const subheadingStyles = {
  root: {
    fontWeight: FontWeights.semibold,
    color: '#555',
    marginTop: '15px',
    marginBottom: '5px',
  },
};

const textStyles = {
  root: {
    color: '#666',
    lineHeight: '1.6',
    marginTop: '1px',
    marginBottom: '25px'
  },
};

export default TermsOfUse;

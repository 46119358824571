import {
    ITimestampProvider,
    LivePresence,
    LivePresenceUser,
    PresenceState,
    UserMeetingRole,
} from "@microsoft/live-share";
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { app } from "@microsoft/teams-js";

export interface IUserData {
    vote: String;
    hasVoted: boolean;
}

export interface ITeamsUser{
    displayName: string | undefined,
    hasVoted: boolean | undefined,
    isLocalUser: boolean | undefined,
    userId: string | undefined,
    vote: String | undefined,
}

/**
 * Hook for tracking users, roles, and who is in control
 *
 * @remarks
 *
 * @param {LivePresence<IUserData>} presence presence object from Fluid container.
 * @param {microsoftTeams.app.Context} context Teams context objecting
 * - `presenceStarted` is a boolean indicating whether `presence.initialize()` has been called.
 * - `localUser` is the local user's presence object.
 * - `users` is an array of user presence objects in the session.
 * - `localUserIsEligiblePresenter` is a boolean indicating whether the local user is an eligible presenter.
 */
export const usePresence = (
    presence?: LivePresence<IUserData>,
    context?: app.Context,
) => {
    const startedInitializingRef = useRef(false);
    const [users, setUsers] = useState<ITeamsUser[]>([]);
    const [presenceUsers, setPresenceUsers] = useState<LivePresenceUser<IUserData>[]>([]);
    const [localUser, setLocalUser] = useState<ITeamsUser>();
    const [presenceLocalUser, setPresenceLocalUser] = useState<LivePresenceUser<IUserData>>();
    const [presenceStarted, setStarted] = useState(false);

    const castVote = useCallback(
        (vote: string, hasVoted: boolean) => {
            if (!presence) return;

            presence.update({
                hasVoted: hasVoted,
                vote: vote
            })
        },
        [presence]
    );


    useEffect(() => {
        if (
            !presence ||
            presence.isInitialized ||
            !context ||
            startedInitializingRef.current
        )
            return;
        startedInitializingRef.current = true;
        // Register presenceChanged event listener
        presence.on(
            "presenceChanged",
            (userPresence: LivePresenceUser<IUserData>, local) => {
                console.log(
                    "usePresence: presence received",
                    userPresence,
                    local
                );
                if (local) {
                    setPresenceLocalUser(userPresence);
                    setLocalUser({
                        displayName: userPresence.displayName,
                        isLocalUser: userPresence.isLocalUser,
                        userId: userPresence.userId,
                        vote: userPresence.data?.vote,
                        hasVoted: userPresence.data?.hasVoted,

                    });
                }
                // Set users local state
                const userArray = presence.getUsers(PresenceState.online);
                
                setPresenceUsers([...userArray]);

                setUsers([...userArray.map(user => (
                    {
                        displayName: user.displayName,
                        isLocalUser: user.isLocalUser,
                        userId: user.userId,
                        vote: user.data?.vote,
                        hasVoted: user.data?.hasVoted,
                    }
                ))]);
            }
        );

        const userData: IUserData = {
            vote: "",
            hasVoted: false
        };        
debugger;
        presence
            .initialize(userData)
            .then(() => {
                setStarted(true);
            })
            .catch((error) => console.error(error));
    }, [presence, context]);

    return {
        presenceStarted,
        localUser,
        users,
        castVote: castVote,
    };
};

import React from 'react';
import { ITeamsUser } from './utils/usePresence';
import UserPersona from './UserPersona';
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

interface AttendenceListProps {
    onlineUsers: ITeamsUser[],
    appState: string
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',   
    overflowY: 'auto',
    ...shorthands.padding('8px', '0'),
  },
  userItem: {
    ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStroke2),
    '&:last-child': {
      borderBottom: 'none',
    },
  },
});

const AttendenceList: React.FC<AttendenceListProps> = ({ onlineUsers, appState }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {onlineUsers.map((user, index) => (
        <div key={user.userId || index} >
          <UserPersona appState={appState} user={user} />
        </div>
      ))}
    </div>
  );
};

export default AttendenceList;

import * as microsoftTeams from "@microsoft/teams-js";
import { app } from "@microsoft/teams-js";
import { useEffect, useState, useRef } from "react";

export const useTeamsContext = (webDebugMode: boolean) => {
    const startedRef = useRef(false);
    const [ctx, setCtx] = useState<app.Context>();
    const [isInTeams, setIsInTeams] = useState(false);
    

    useEffect(() => {
        if (startedRef.current) return;
        startedRef.current = true;

        if(webDebugMode){
            console.log("app loaded in debug mode: creating fake context.");
            setCtx(createFakeContext());
            setIsInTeams(true);
        }else if (!ctx?.user?.id) {
            console.log("useTeamsContext: Attempting to get Teams context");            
            microsoftTeams.app.getContext().then((context) => {
                setIsInTeams(true);
                setCtx(context);
            })
            .catch((error) => {
                console.error(error);
                setIsInTeams(false);
            });
            
        }
    }, [ctx?.user?.id, webDebugMode]);

    return { context: ctx, isInTeams };
};

const createFakeContext = (): app.Context => {
    const fakeHost: app.AppHostInfo = {
        name: microsoftTeams.HostName.teams,
        clientType: microsoftTeams.HostClientType.web,
        sessionId: "",
    };

    const fakeAppInfo: app.AppInfo = {
        locale: "",
        theme: "",
        sessionId: "",
        host: fakeHost,
    };

    const fakePageInfo: app.PageInfo = {
        id: "",
        frameContext: microsoftTeams.FrameContexts.meetingStage,
    };

    const fakeUserInfo: app.UserInfo = {
        id: `user${Math.abs(Math.random() * 999999999)}`,
    };

    const fakeContext: app.Context = {
        app: fakeAppInfo,
        page: fakePageInfo,
        user: fakeUserInfo,
        dialogParameters: {}
    };

    return fakeContext;
};

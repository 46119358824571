import VotingCard, { CardValueType } from "./VotingCard";
import { Stack, IStackTokens } from '@fluentui/react'
import { useState } from "react";
import { VoteStatus } from "./UserPersona";

interface IVotingCards {
    cardValues: string[] | undefined,
    onCastVote: (vote: string, hasVoted: boolean) => void,
}

const VotingCards = ({cardValues, onCastVote}: IVotingCards) =>{
    const stackTokens: IStackTokens = { childrenGap: 40 }
    const [selectedValue, setSelectedValue] = useState("-");
    const onVoteChanged = function( voteValue: string){
        if(voteValue === selectedValue){
          setSelectedValue(VoteStatus.noVote);
          onCastVote(VoteStatus.noVote, false);
        }else{
          setSelectedValue(voteValue);
          onCastVote(voteValue, true);
        }
        
    }

    const getCardValueType = function(index: number){
      let separationPoint = cardValues? cardValues.length/3 : 0;
      
      if(index < separationPoint)
        return CardValueType.low;

      if(index >= separationPoint*2)
        return CardValueType.high;

      return CardValueType.medium;
  }
   

  return (
    <> 
      {cardValues?.map((cardValue, index) => <VotingCard cardValueType={getCardValueType(index)} isSelected={selectedValue === cardValue} onCardClicked={onVoteChanged} value={cardValue}></VotingCard>)}
      
    </>
  );
};

export default VotingCards;
/**
 * Checks if the webDebugMode is set to true in the URL search parameters.
 * @returns {boolean} True if webDebugMode is set to 'true', false otherwise.
 */
export const isWebDebugMode = (): boolean => {
    const currentUrl = window.location.href;
    // Check if using HistoryRouter
    const url = currentUrl.includes("/#/")
        ? new URL(`${window.location.href.split("/#/").join("/")}`)
        : new URL(window.location.href);
    const params = url.searchParams;

    console.log(`##InTeams = ${params.get("webDebugMode")}`)
    return params.get("webDebugMode") === "true";
};

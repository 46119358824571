import * as teamsJs from "@microsoft/teams-js";
import { useEffect, useRef, useState } from "react";
import { Theme, teamsDarkTheme, teamsHighContrastTheme, teamsLightTheme } from "@fluentui/react-components";

export const useInitializeTeamsApp = (isDebugMode: boolean) => {
    const [teamsTheme, setTeamsTheme] = useState<Theme>(teamsLightTheme);
    const [teamsSDKLoaded, setTeamsSDKLoaded] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const startedInitializingRef = useRef(false);

    useEffect(() => {
        if (startedInitializingRef.current || isDebugMode) return;
        
        startedInitializingRef.current = true;
        
        const initialize = async () => {
            try {
                console.log("initializeTeamsApp: initializing client SDK");
                await teamsJs.app.initialize();
                teamsJs.app.notifyAppLoaded();
                teamsJs.app.notifySuccess();
                const context = await teamsJs.app.getContext();
                const curTheme = context.app.theme;
                switch (curTheme) {
                    case "dark":
                        setTeamsTheme(teamsDarkTheme);
                        break;
                    case "contrast":
                        setTeamsTheme(teamsHighContrastTheme);
                        break;
                    case "default":
                    default:
                        setTeamsTheme(teamsLightTheme);
                        break;
                }
                teamsJs.app.registerOnThemeChangeHandler(
                    (theme: string | undefined) => {
                        if (theme == "dark") {
                            setTeamsTheme(teamsDarkTheme);
                        } else if (theme == "contrast") {
                            setTeamsTheme(teamsHighContrastTheme);
                        } else {
                            setTeamsTheme(teamsLightTheme);
                        }
                    }
                );

                setTeamsSDKLoaded(true);
                setInitialized(true);
            } catch (error) {
                console.error(error);
                setTeamsSDKLoaded(false);
                setInitialized(true);
            }
        };

        initialize();
    }, [isDebugMode]);

    return { teamsTheme, teamsSDKLoaded, initialized };
};

import {
    ILiveShareJoinResults,
    LiveShareClient,
    TestLiveShareHost,
    LiveState,
    LivePresence,
} from "@microsoft/live-share";
import { useEffect, useState, useRef } from "react";
import { LiveShareHost } from "@microsoft/teams-js";
import { IUserData } from "./usePresence";

export interface IAppState {
    state: string | undefined,
    deckConfiguration: string[] | undefined
}

interface IUseSharedObjectsResult extends Partial<ILiveShareJoinResults> {
    presence: LivePresence<IUserData> | undefined;
    appState: LiveState<IAppState> | undefined;
    error: Error | undefined;
}

/**
 * Hook that creates/loads the apps shared objects.
 *
 * @remarks
 * This is an application specific hook that defines the fluid schema of Distributed Data Structures (DDS)
 * used by the app and passes that schema to the `LiveShareClient` to create/load your Fluid container.
 *
 * @returns Shared objects managed by the apps fluid container.
 */
export function useSharedObjects(webDebugMode:boolean): IUseSharedObjectsResult {   
    const startedRef = useRef(false);
    const [results, setResults] = useState<ILiveShareJoinResults>();
    const [error, setError] = useState();
    const [host] = useState( !webDebugMode ? LiveShareHost.create() : TestLiveShareHost.create());

    useEffect(() => {
        // This hook should only be called once, so we use a ref to track if it has been called.
        // This is a workaround for the fact that useEffect is called twice on initial render in React V18.
        // In production, you might consider using React Suspense if you are using React V18.
        // We are not doing this here because many customers are still using React V17.
        // We are monitoring the React Suspense situation closely and may revisit in the future.
        if (startedRef.current) return;
        startedRef.current = true;
        console.log("useSharedObjects: starting");

        try {
            // Enable debugger
            window.localStorage.debug = "fluid:*";
        } catch (error) {
            // Some users or anonymous modes in browsers disable local storage
            console.error(error);
        }

        // Define container schema
        const schema = {
            initialObjects: {
                presence: LivePresence<IUserData>,
                state: LiveState<IAppState>
            },
        };

        // Create live share host
        
        const client = new LiveShareClient(host);
        client
            .joinContainer(schema)
            .then((results) => {
                console.log("useSharedObjects: joined container");
                setResults(results);
            })
            .catch((err) => {
                console.error(err);
                setError(err);
            });
    }, []);

    const container = results?.container;
    const initialObjects = container?.initialObjects;
    return {
        presence: initialObjects?.presence as
            | LivePresence<IUserData>
            | undefined,
        appState: initialObjects?.state as
            | LiveState<IAppState>
            | undefined,
        container,
        error,
        services: results?.services,
    };
}
